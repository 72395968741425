﻿/************************************************************************/
/* Debug functions                                                      */
/************************************************************************/
assert = function (condition, message) {
    if (false == condition){
        alert(message);
    }
}

/************************************************************************/
/* Common functions                                                     */
/************************************************************************/

function showLoading(container) {
  container.html(
    "<div class=\"ui segment\" style=\"height: 500px\">\r\n  <div class=\"ui active inverted dimmer\">\r\n    <div class=\"ui text loader\">Loading</div>\r\n  </div>\r\n  <p></p>\r\n</div>");
}

CheckTimeStr = function (d) {
    return !isNaN(Date.parse(d));
}

Str2TimeStr = function (datetimestr, onlydate) {
    /// <summary>
    ///     将.Net时间字符串转换为可读的时间字符串返回
    /// </summary>
    /// <param name="datetimestr" type="String">
    ///     待转换的表示时间的字符串，有两种情况：
    ///         "/Date(1372778537000)/"，去掉前6个字符进行parseInt就行
    ///         "2016-3-27 19:18:55"，
    /// </param>
    /// <param name="type" type="Number">
    ///     转换后的时间的类型，国外的(非0)，返回值类似 "26/06/2013 00:00:00"
    /// 国内的(0)，返回值类似"2013年6月2日 23:22:23"
    /// </param>
    /// <param name="onlydate" type="Boolean">
    ///     转换后的时间的类型长度
    ///     只有日期(true)，返回值类似 "26/06/2013" "2013年6月2日"
    ///     有日期和时间(false)，返回值类似 "26/06/2013 00:00:00" "2013年6月2日 23:22:23"
    /// </param>
    /// <returns type="String" />
    var dateTime;
    if(datetimestr.substr(0, 6) === "/Date(")
        dateTime = new Date(parseInt(datetimestr.substr(6)));
    else if (CheckTimeStr(datetimestr)) {
        dateTime = new Date(datetimestr);
    } else {
        assert(false, "时间格式有误");
        return datetimestr;
    }
    var year = dateTime.getFullYear();
    var month = dateTime.getMonth() + 1;    //getMonth()返回的值为0~11
    var day = dateTime.getDate();
    var hour = dateTime.getHours();
    if (hour < 10) {
        hour = '0' + hour;
    }
    var min = dateTime.getMinutes();
    if (min < 10) {
        min = '0' + min;
    }
    if (onlydate) {
        return year + '-' + month + '-' + day;
    }
    else {
        return year + '-' + month + '-' + day + ' ' + hour + ':' + min;
    }
}

String.prototype.hashCode = function () {
    var hash = 0, i, chr, len;
    if (this.length == 0) return hash;
    for (i = 0, len = this.length; i < len; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

// ReSharper disable once NativeTypePrototypeExtending
String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

function InitDisplayDate() {
  $('.display-date').text(function(i, t) {
    const date = new Date(t);
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
  });
}

// MsgBox
(function () {
    function showMsgBox(header, content, fullScreen) {
		  if (!content) return;
      const box = $('#MsgBox');
      const headerElement = box.children('.header');
      const contentElement = box.children('.content');
      if (fullScreen) {
        box.addClass('fullscreen');
        contentElement.addClass('scrolling');
      } else {
        box.removeClass('fullscreen');
        contentElement.removeClass('scrolling');
      }
		  headerElement.text('');
		  contentElement.html('');
      headerElement.text(header || ZCText.Message);
      contentElement.html(content);
      box.modal({closable: false}).modal('show');
    }

    function showFullscreenMsgBox(header, content) {
      showMsgBox(header, content, true);
    }

    ZCEvent.MsgBox = showMsgBox;
    ZCEvent.ShowFullscreenMsgBox = showFullscreenMsgBox;
})();

// go back
(function () {
    // To enable support for HTML5-History-API polyfill in your library
    var location = window.history.location || window.location;

    // hang on popstate event triggered by pressing back/forward in browser
    $(window).on('popstate', function (e) {
        var event = e.originalEvent;
        var state = event.state;
        if (state == null) {
            return;
        }
        var src = state.src;
        ZCEvent.LoadMain(src);
        console.log("LoadMain with " + src);
    });
})();

// init links
(function () {
    var _InitLinkInSide = function (container) {
        // looking for all the links and hang on the event, all references in the container
        $(container).on('click', '*[data-src]', function () {
            //TODO If data-src links to the current page, don't do anything.
            var src = $(this).data("src");
            if (!src) return false;

            history.pushState({ "src": src }, null, src);

            ZCEvent.LoadMain(src);

            return false;
        });

    }
    ZCEvent.InitLinkInSide = _InitLinkInSide;
}());


//dynamic message
(function() {

  function setMessage(messageElement, type, header, content) {
    let messageClass = null;
    let iconClass = 'info';
    if (type === 'error') {
      messageClass = 'error';
      iconClass = 'red exclamation circle';
    }
    else if (type === 'warning') {
      messageClass = 'warning';
      iconClass = 'yellow exclamation triangle';
    }
    else if (type === 'loading') {
      iconClass = 'notched circle loading';
    } else if(type === 'positive'){
      messageClass = 'positive';
      iconClass = 'green check circle';
    } else if(type === 'negative'){
      messageClass = 'negative';
      iconClass = 'red minus circle';
    } else if (type === 'info') {
      messageClass = 'info';
      iconClass = 'comment';
    }

    $(messageElement).removeClass('error warning loading positive negative info');
    if (messageClass) {
       $(messageElement).addClass(messageClass);
    }

    const html =
`<i class="${iconClass} icon"></i>
  <div class="content">
  <div class="header">${header}</div>
  <p>${content}</p>
  </div>`;
    $(messageElement).html(html);
    $(messageElement).removeClass('hidden');
  }

  function clearAndHideMessage(messageElement) {
    $(messageElement).html('');
    $(messageElement).addClass('hidden');
  }

  ZCEvent.SetMessage = setMessage;
  ZCEvent.ClearAndHideMessage = clearAndHideMessage;
}());

(function () {
  function showCountDown(second, formatFunc, container) {
    const msg = $(`<div class="ui warning text leftTime">${formatFunc(second)}</div>`);
    container.append(msg);
    const countId = setInterval(() => {
      second--;
      if (second < 0) {
        clearInterval(countId);
        msg.remove();
      }
      msg.text(`${formatFunc(second)}`);
    }, 1000);
  }
  ZCEvent.ShowCountDown = showCountDown;
}());